import React ,{useState}from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../assets/css/Navigation.css';
import { useTranslation } from 'react-i18next';

export default function Navigation() {
  const languages = [
    {
      name: 'English',
      flag: '🇬🇧',
      code: 'en',
    },
    {
      name: 'German',
      flag: '🇩🇪',
      code: 'de',
    },
    {
      name: 'Chinese',
      flag: '🇨🇳',
      code: 'zh',
    },
    {
      name: 'Japanese',
      flag: '🇯🇵',
      code: 'ja',
    },
    {
      name: 'Arabic',
      flag: '🇸🇦',
      code: 'ar',
    },
    {
      name: 'Persian',
      flag: '🇮🇷',
      code: 'fa',
    },
    {
      name: 'Hindi',
      flag: '🇮🇳',
      code: 'hi',
    },
    {
      name: 'Russian',
      flag: '🇷🇺',
      code: 'ru',
    },
  ];

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState({
    name: 'English',
    flag: '🇬🇧',
    code: 'en',
  });
  const handleChange = (event) => {
    setLanguage({
      name: event.target.options[event.target.selectedIndex].name,
      flag: event.target.options[event.target.selectedIndex].dataset.flag,
      code: event.target.options[event.target.selectedIndex].code,
    });
    i18n.changeLanguage(event.target.value)
    console.log(event.target.value);
  };


  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className='container'>
      <nav className='navbar navbar-expand-lg navbar-light bg-transparent'>
        <a className='navbar-brand' href='/'>
        🎓 CLASS MATE
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarNav'
          aria-controls='navbarNav'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarNav'>
          <ul className='navbar-nav'>
            <li className='nav-item'>
              <a
                className='nav-link'
                href='https://materials.springer.com/'
                type='button'
              >
                Springer Materials
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='https://lass-kg.de/' type='button'>
              {t('research_projectpage')}
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='https://huggingface.co/CLASS-MATE/' type='button'>
                Hugging Face
              </a>
            </li>
          </ul>
        </div>
        <div className='select-container'>
          <select value={language.name} onChange={handleChange}>
            {languages.map((language) => (
              <option
                key={language.name}
                value={language.code}
                data-flag={language.flag}
              >
                {language.flag} {language.name}
              </option>
            ))}
          </select>
        </div>

      </nav>
    </div>
  );
}
